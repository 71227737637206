import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// About Page GraphQL Queries
export const query = graphql`
  query home {
    site {
      siteMetadata {
        description
        title
        author
        backgrounds {
          black
          normal
          transparent
        }
      }
    }
  }
`

const Home = ({ data, backgroundStyleVal, location }) => {
  console.log(backgroundStyleVal)
  const name = "francesco" // Name used for home page

  return (
    <>
      <Helmet title={`${data.site.siteMetadata.title}`} />
      <div className="home">
        <div className="intro fold">
          <h1>
            {name.split("").map(function (char, index) {
              return (
                <span
                  aria-hidden="true"
                  key={index}
                  className="letter-parent"
                >
                  <span className={`letter-child letter-child-${index}`}>
                    {char}
                  </span>
                </span>
              )
            })}
          </h1>
          <div className="job-title">
            <p>frontend developer</p>
            <span className="line"></span>
            <p>toronto, on</p>
          </div>
          <Link className="main-cta" to="/portfolio">
            View Work
          </Link>
        </div>
      </div>
    </>
  )
}

export default Home
